// Home.js
import React, { useEffect, useState } from 'react';
import './home.css'; // Import the CSS file

function Home() {
    const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
    const [currentSentence, setCurrentSentence] = useState('Find a Job');

    useEffect(() => {
        const sentences = ['Find a Job', 'Find an Internship', 'Find an Apprenticeship', 'Find a Research'];
        const intervalId = setInterval(() => {
            setCurrentSentenceIndex(prevIndex => (prevIndex + 1) % sentences.length);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const sentences = ['Find a Job', 'Find an Internship', 'Find an Apprenticeship', 'Find a Research'];
        setCurrentSentence(sentences[currentSentenceIndex]);
    }, [currentSentenceIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <section id="home" className="home">
            <h2>HELP ME</h2>
            <div className="animation-text">{currentSentence}</div>
        </section>
    );
}

export default Home;
