import React from 'react';
import Navbar from './Components/navbar/navbar';
import Home from './Components/home/home';
import About from './Components/about/about';
import Signup from './Components/signup/signup';

function MainPage() {
    return (
        <div className="MainPage">
            <Navbar />
            <Home />
            <Signup />
            <About />
            {/* Add other components here */}
        </div>
    );
}

export default MainPage;
