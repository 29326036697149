import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import './notify.css'; // Import CSS file

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAdPzcW-2p_phNC4w4HGmgMeezpthS3lQk",
    authDomain: "reactconnectai.firebaseapp.com",
    databaseURL: "https://reactconnectai-default-rtdb.firebaseio.com",
    projectId: "reactconnectai",
    storageBucket: "reactconnectai.appspot.com",
    messagingSenderId: "332686943435",
    appId: "1:332686943435:web:8b4163e6fe1508e6e4e74e",
    measurementId: "G-6DV3WB24MR"
};

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const Notify = () => {
    const [firstName, setFirstName] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFirstName = async () => {
            try {
                const user = firebase.auth().currentUser;
                if (user) {
                    let userProfile = null;

                    // Try fetching by email
                    const emailKey = user.email.replace('.', '_');
                    let userProfileSnapshot = await firebase.database().ref(`profiles/${emailKey}`).once('value');
                    userProfile = userProfileSnapshot.val();

                    // If not found, try fetching by UID
                    if (!userProfile) {
                        userProfileSnapshot = await firebase.database().ref(`profiles/${user.uid}`).once('value');
                        userProfile = userProfileSnapshot.val();
                    }

                    if (userProfile && userProfile.firstName) {
                        setFirstName(userProfile.firstName);
                    } else {
                        setFirstName('User'); // Default name if firstName is not available
                    }
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
                setFirstName('User'); // Default name on error
            } finally {
                setLoading(false);
            }
        };

        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                fetchFirstName();
            } else {
                setLoading(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    const handleSignOut = () => {
        firebase.auth().signOut().then(() => {
            window.location.href = '/';
        }).catch((error) => {
            console.error('Sign Out Error:', error);
        });
    };

    if (loading) {
        return <p className="loading-message1">Loading....</p>;
    }

    return (
        <div className="notify-container">
            <div className="notify-content">
                <p className="notify-text">
                    <span className="navy-blue">Hello, {firstName}!</span>
                </p>
                <p className="notify-text">
                    <span className="navy-blue">We Got Your Connection</span>
                    
                </p>
                <p className="notify-text">
                    <span className="navy-blue">We will notify you...</span>
                </p>
            </div>
            <button className="sign-out-button" onClick={handleSignOut}>Sign Out</button>
        </div>
    );
}

export default Notify;
