import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import './profile.css';

const firebaseConfig = {
    apiKey: "AIzaSyAdPzcW-2p_phNC4w4HGmgMeezpthS3lQk",
    authDomain: "reactconnectai.firebaseapp.com",
    databaseURL: "https://reactconnectai-default-rtdb.firebaseio.com",
    projectId: "reactconnectai",
    storageBucket: "reactconnectai.appspot.com",
    messagingSenderId: "332686943435",
    appId: "1:332686943435:web:8b4163e6fe1508e6e4e74e",
    measurementId: "G-6DV3WB24MR"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const database = firebase.database();
const storage = firebase.storage();

function Profile() {
    const [user, setUser] = useState(null);
    const [profile, setProfile] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        address: '',
        phoneNumber: '',
        gender: '',
        domain: '',
        profession: '',
        resumeUrl: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                setUser(user);
                const userProfileExists = await checkUserProfileExists(user.email, user.uid);
                if (userProfileExists) {
                    window.location.href = '/notify';
                } else {
                    fetchUserProfile(user.uid);
                }
            } else {
                setUser(null);
            }
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    const checkUserProfileExists = async (email, uid) => {
        try {
            const snapshotByEmail = await database.ref(`profiles/${email.replace('.', '_')}`).once('value');
            const snapshotByUID = await database.ref(`profiles/${uid}`).once('value');
            return snapshotByEmail.exists() || snapshotByUID.exists();
        } catch (err) {
            console.error('Error checking profile existence:', err);
            setError('Failed to check profile existence. Please check your internet connection.');
            return false;
        }
    };

    const fetchUserProfile = async (uid) => {
        try {
            const snapshot = await database.ref(`profiles/${uid}`).once('value');
            const userData = snapshot.val();
            if (userData) {
                setProfile(userData);
            }
        } catch (err) {
            console.error('Error fetching profile:', err);
            setError('Failed to fetch profile. Please check your internet connection.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfile((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        const storageRef = storage.ref();
        const fileRef = storageRef.child(`${user.email.replace('.', '_')}/${e.target.name}/${file.name}`);
        try {
            const snapshot = await fileRef.put(file);
            const fileUrl = await snapshot.ref.getDownloadURL();
            setProfile((prevState) => ({
                ...prevState,
                [e.target.name]: fileUrl
            }));
        } catch (error) {
            console.error('Error uploading file:', error);
            setError('Failed to upload file. Please try again.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true);

        const fileInput = document.getElementById('resume');
        if (fileInput && fileInput.files.length === 0) {
            setError('Please upload a resume.');
            setSaving(false);
            return;
        }

        try {
            if (fileInput && fileInput.files.length > 0) {
                await handleFileChange({ target: fileInput });
            }
            const userRef = database.ref(`profiles/${user.email.replace('.', '_')}`);
            await userRef.set(profile);
            window.location.href = '/notify';
        } catch (err) {
            console.error('Error updating profile:', err);
            setError('Failed to update profile. Please try again.');
        } finally {
            setSaving(false);
        }
    };

    const handleSignOut = () => {
        firebase.auth().signOut().then(() => {
            window.location.href = '/';
        }).catch((error) => {
            console.error('Sign Out Error:', error);
            setError('Failed to sign out. Please try again.');
        });
    };

    if (loading) {
        return <div className="loading-message">Making Your Connection, Please Wait...</div>;
    }

    return (
        <div className="profile-container">
            <div className="profile">
                <div className="profile-header">
                    <h1>Profile</h1>
                    {user && <button className="sign-out" onClick={handleSignOut}>Sign Out</button>}
                </div>
                {error && <p className="error">{error}</p>}
                {user && (
                    <>
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="firstName">First Name: <span className="required">*</span></label>
                            <input type="text" id="firstName" name="firstName" value={profile.firstName} onChange={handleInputChange} required />
                            <label htmlFor="middleName">Middle Name:</label>
                            <input type="text" id="middleName" name="middleName" value={profile.middleName} onChange={handleInputChange} />
                            <label htmlFor="lastName">Last Name: <span className="required">*</span></label>
                            <input type="text" id="lastName" name="lastName" value={profile.lastName} onChange={handleInputChange} required />
                            <label htmlFor="address">Address: <span className="required">*</span></label>
                            <input type="text" id="address" name="address" value={profile.address} onChange={handleInputChange} required />
                            <label htmlFor="phoneNumber">Phone Number: <span className="required">*</span></label>
                            <input type="text" id="phoneNumber" name="phoneNumber" value={profile.phoneNumber} onChange={handleInputChange} placeholder="+911234567890" required />
                            <label htmlFor="gender">Gender:<span className="required">*</span></label>
                            <select id="gender" name="gender" value={profile.gender} onChange={handleInputChange} required>
                                <option value="">Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                            <label htmlFor="domain">Domain: <span className="required">*</span></label>
                            <input type="text" id="domain" name="domain" value={profile.domain} onChange={handleInputChange} placeholder="AIML, Software Development, etc." required />
                            <label htmlFor="profession">Profession:<span className="required">*</span></label>
                            <select id="profession" name="profession" value={profile.profession} onChange={handleInputChange} required>
                                <option value="">Select</option>
                                <option value="Student">Student</option>
                                <option value="Teacher">Teacher</option>
                                <option value="Researcher">Researcher</option>
                            </select>
                            <label htmlFor="resume">Upload Resume: <span className="required">*</span></label>
                            <input type="file" id="resume" name="resumeUrl" onChange={handleFileChange} required />
                            {saving && (
                                <div className="saving-message">
                                    Saving Your Profile. Please Wait...
                                </div>
                            )}
                            <button type="submit" disabled={saving}>Save Profile</button>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
}

export default Profile;
