import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MainPage from './mainpage';
import Navbar from './Components/navbar/navbar';
import Home from './Components/home/home';
import About from './Components/about/about';
import Signup from './Components/signup/signup';
import Profile from './Components/profile/profile';
import Notify from './Components/notify/notify';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route path="/navbar" component={Navbar} />
          <Route path="/home" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/signup" component={Signup} />
          <Route path="/profile" component={Profile} />
          <Route path="/notify" component={Notify} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
