import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import ReCAPTCHA from 'react-google-recaptcha';
import './signup.css';

// Replace this with your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAdPzcW-2p_phNC4w4HGmgMeezpthS3lQk",
    authDomain: "reactconnectai.firebaseapp.com",
    databaseURL: "https://reactconnectai-default-rtdb.firebaseio.com",
    projectId: "reactconnectai",
    storageBucket: "reactconnectai.appspot.com",
    messagingSenderId: "332686943435",
    appId: "1:332686943435:web:8b4163e6fe1508e6e4e74e",
    measurementId: "G-6DV3WB24MR"
};

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export default function Signup() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSignup = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        if (password !== confirmPassword) {
            setErrorMessage("Passwords don't match");
            return;
        }

        if (!recaptchaToken) {
            setErrorMessage("Please complete the reCAPTCHA");
            return;
        }

        try {
            await firebase.auth().createUserWithEmailAndPassword(email, password);
            setSuccessMessage('Signup successful! Redirecting to profile...');
            setTimeout(() => {
                window.location.href = '/profile'; // Update the path accordingly
            }, 2000);
        } catch (error) {
            console.error('Error signing up:', error);
            if (error.code === 'auth/email-already-in-use') {
                setErrorMessage('The email address is already in use by another account.');
            } else if (error.code === 'auth/invalid-email') {
                setErrorMessage('The email address is not valid.');
            } else if (error.code === 'auth/weak-password') {
                setErrorMessage('The password is too weak.');
            }else if (error.code === 'auth/email-already-in-use') {
                setErrorMessage('The email address is already in use by another account.');
            } else {
                setErrorMessage(error.message);
            }
        }
    };

    const handleRecaptchaChange = (value) => {
        setRecaptchaToken(value);
    };

    const handleGoogleSignup = async () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        try {
            await firebase.auth().signInWithPopup(provider);
            // No need to assign the result to a variable if it's not used
            // Proceed with signup or any additional logic
            setSuccessMessage('Signup with Google successful! Redirecting to profile...');
            setTimeout(() => {
                window.location.href = '/profile'; // Update the path accordingly
            }, 2000);
        } catch (error) {
            console.error('Error signing up with Google:', error);
            setErrorMessage(error.message);
        }
    };
    
    
    return (
        <section id="signup" className="signup">
            <h2>Signup</h2>
            <form onSubmit={handleSignup}>
                <div>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="confirmPassword">Confirm Password:</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="recaptcha-container">
                    <ReCAPTCHA
                        sitekey="6Lfs1NspAAAAACCTJOb_NPVlZXkEDzj6Pd8nBOQn" // Replace with your reCAPTCHA site key
                        onChange={handleRecaptchaChange}
                    />
                </div>
                {errorMessage && <p className="error">{errorMessage}</p>}
                {successMessage && <p className="success">{successMessage}</p>}
                <button type="submit">Signup</button>
                <div className="additional-options">
                    <div className="google-signup" onClick={handleGoogleSignup}>
                        <img src="https://img.icons8.com/color/16/000000/google-logo.png" alt="Google logo" />
                        Signup with Google
                    </div>
                    <a href="/" className="forgot-password">Forgot Password?</a>
                </div>
            </form>
        </section>
    );
}
