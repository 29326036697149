// About.js
import React from 'react';
import './about.css'; // Import the CSS file

function About() {
    return (
        <section id="about" className="about">
            <div className="about-content">
                <h1>About Us</h1>
                <p>Welcome to Connect.ai!</p>
                <p>At Connect.ai, we are dedicated to revolutionizing the job search process through cutting-edge technology. Our platform leverages the power of Artificial Intelligence and Natural Language Processing (NLP) to connect talented individuals with their dream jobs.</p>
                <p>With our innovative approach, we strive to simplify and streamline the job search experience, making it more efficient and effective for both job seekers and employers.</p>
            </div>
        </section>
    );
}

export default About;
